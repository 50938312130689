<template>
  <div class="container ">
    <PageMainHeading title="VoIP Packages">
      <div class="d-flex w-100 justify-content-end page-heading-toolbar">
        <div class="page-heading-toolbar--col">
          <ShadowControl
            class="search-control"
            icon="search"
            placeholder="Search..."
            name="accounts-search"
          />
        </div>
        <div class="page-heading-toolbar--col">
          <FilterBtn :count="3" />
        </div>
      </div>
    </PageMainHeading>

    <div class="account-wrapper pb-5" style="max-width: 100%; overflow: auto">
      <TableView class="accordion-table inline-table">
        <template #table-head>
          <TableHeadCell name="Country" sort="a" size="xl" />
          <TableHeadCell name="Packages" size="smL" />
          <TableHeadCell name="Date created" size="lg" sort="a" />
          <TableHeadCell name="Created by" size="lg" sort="a" />
        </template>

        <template #table-body>
          <VirtualPackageCountryRow v-for="n in 10" :key="n" />
        </template>
        <template #table-footer>
          <TableFooter
            :show-count="10"
            :use-select="true"
            count-text="6 of 17 transactions"
            :pagination="{
              current: 1,
              total: 10
            }"
          />
        </template>
      </TableView>
    </div>
  </div>
</template>

<script>
import PageMainHeading from "@/components/Layout/PageMainHeading";
import ShadowControl from "@/components/Form/ShadowControl";
import FilterBtn from "@/components/ui/Buttons/FilterBtn";
import TableView from "@/components/Table/TableView";
import TableHeadCell from "@/components/Table/TableHeadCell";
import VirtualPackageCountryRow from "@/components/Packages/VirtualPackageCountryRow";
import TableFooter from "@/components/Table/TableFooter";

export default {
  name: "VirtualNumbersPackages",
  components: {
    VirtualPackageCountryRow,
    TableHeadCell,
    TableView,
    FilterBtn,
    ShadowControl,
    PageMainHeading,
    TableFooter
  }
};
</script>

<style scoped></style>
