<template>
  <div class="package d-flex align-items-center justify-content-between">
    <div class="package-info d-flex align-items-center">
      <div class="package-name">{{ validity }}</div>
      <ul
        v-if="servicesIcons"
        class="package-services d-flex align-items-center ms-3"
      >
        <li
          v-for="(serviceIcon, idx) in servicesIcons"
          :key="serviceIcon"
          :class="{
            'me-2': idx < services.length - 1
          }"
        >
          <svg-icon :icon="serviceIcon" />
        </li>
      </ul>
    </div>
    <div class="d-flex">
      <Btn
        type="primary-transparent"
        size="icon"
        @click="$emit('edit', planId)"
      >
        <SvgIcon icon="pen" />
      </Btn>

      <Btn
        type="danger-transparent"
        size="icon"
        @click="$emit('delete', planId)"
      >
        <SvgIcon icon="trash" />
      </Btn>
    </div>
  </div>
</template>

<script>
import { getIconByServiceName } from "@/components/Packages/getIconByServiceName";
import SvgIcon from "@/components/ui/SvgIcon";
import Btn from "@/components/ui/Buttons/Button";

export default {
  name: "PackagePlan",
  components: { Btn, SvgIcon },
  props: {
    planId: { type: String, required: true },
    validity: {
      type: String,
      required: true
    },
    services: { type: Array, required: false, default: () => [] }
  },
  emits: ["delete", "edit"],

  computed: {
    servicesIcons() {
      if (!this.services.length) {
        return null;
      }

      return this.services.map(serviceName => {
        return getIconByServiceName(serviceName);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.package {
  box-shadow: $shadow-700;
  border-radius: $border-radius;
  font-size: 1.07rem;
  padding: 0.8em 1.4em 0.8em 2em;
}
</style>
